/* .advisor-card {
  position: relative;
  width: 1320px;
  padding: 4rem 2rem;
  border-radius: 32px;
  margin-left: 6%;
  background: linear-gradient(
    168deg,
    rgba(60, 54, 46, 1) 5%,
    rgba(59, 55, 49, 1) 22%,
    rgba(59, 59, 57, 1) 50%,
    rgba(34, 37, 38, 1) 76%,
    rgba(21, 24, 25, 1) 94%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.advisor-content {
  display: flex;
  width: 100%;
  max-width: 1140px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.advisor-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10px;
  flex: 1;
  gap: 0.8rem;]
}

.advisor-badge {
  background: transparent;
  border: none;
  color: #ff9900;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  padding: 0;
}

.advisor-title {
  color: #f8f8f8;
  font-size: 2.6rem;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 1.3;
  margin: 0; 
}

.advisor-description {
  color: #9c9c9c;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 300px;
  margin: 0; 
}
.advisor-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
}

.advisor-image-container {
  position: relative;
  width: 544px;
  height: auto;
}

.advisor-image {
  width: 100%;
  object-fit: contain;
}

.advisor-names {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  color: #fff;
}

.advisor-footer {
  color: #9c9c9c;
  font-size: 13px;
  letter-spacing: 1.3px;
  text-align: center;
  font-weight: normal;
  margin-top: 10px;
} */


/* Base Styles (unchanged from your original) */
.advisor-card {
  position: relative;
  width: 88%; /* Replaces margin-left/right 6% */
  max-width: 1320px;
  padding: 4rem 0;
  margin: 0 auto; /* Centers the card */
  margin-bottom: 380px;
  border-radius: 32px;
  background: linear-gradient(
    168deg,
    rgba(60, 54, 46, 1) 5%,
    rgba(59, 55, 49, 1) 22%,
    rgba(59, 59, 57, 1) 50%,
    rgba(34, 37, 38, 1) 76%,
    rgba(21, 24, 25, 1) 94%
  );
  display: flex;
  justify-content: center; /* Better for responsiveness */
}

.advisor-content {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advisor-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2rem;
  flex: 1;
}

.advisor-badge {
  background: transparent;
  border: none;
  color: #ff9900;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  margin-bottom: 1rem;
  padding: 0;
}

.advisor-title {
  color: #f8f8f8;
  font-size: 2.6rem;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 1.3;
  max-width: 426px;
  margin-bottom: 1rem;
  text-align: left;
}

.advisor-description {
  color: #9c9c9c;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 300px;
  text-align: left;
}

.advisor-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 2rem;
}

.advisor-image-container {
  width: 100%;
  max-width: 544px;
}

.advisor-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}

.advisor-footer {
  color: #9c9c9c;
  font-size: 0.8rem;
  letter-spacing: 1.3px;
  text-align: center;
  font-weight: normal;
  margin-top: 0.5rem;
  text-transform: uppercase;
}

/* Responsive Adjustments (added) */
@media (max-width: 1024px) {
  .advisor-card {
    padding: 3rem 0;
  }
  
  .advisor-title {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  .advisor-content {
    flex-direction: column;
    gap: 2rem;
  }
  
  .advisor-left, 
  .advisor-right {
    padding: 0 1.5rem;
    width: 100%;
    align-items: center;
    text-align: center;
  }
  
  .advisor-title,
  .advisor-description {
    max-width: 100%;
    text-align: center;
  }
  
  .advisor-title {
    font-size: 2rem;
  }
  
  .advisor-footer {
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .advisor-card {
    width: 92%;
    padding: 2rem 0;
    border-radius: 24px;
  }
  
  .advisor-title {
    font-size: 1.75rem;
    line-height: 1.2;
  }
  
  .advisor-badge {
    font-size: 0.875rem;
  }
  
  .advisor-description {
    font-size: 0.875rem;
  }
}