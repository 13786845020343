/* Portfolio Container */
.portfolio-container {
  text-align: center;
  background-color: black;
  color: white;
  padding: 40px 20px;
  margin-top: 100px;
}

/* Video Frame */
.video-frame {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px; /* Adjust size as needed */
}

/* Portfolio Image */
.portfolio-image {
  width: 100%;
  border-radius: 20px;
}


/* Overlay Text */
.overlay-text {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 12px;
}

.top-right { 
  top: 10px; 
  right: 10px; 
}

.middle-left { 
  bottom: 50%; 
  left: 10px; 
  transform: translateY(50%); 
}

.bottom-right { 
  bottom: 10px; 
  right: 10px; 
}

/* Portfolio Content */
.portfolio-content {
  margin-top: 20px;
}

/* Portfolio Title */
.portfolio-title {
  color: white;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 96px;
  font-weight: 500;
  line-height: 102px;
  letter-spacing: 1px;
}

/* Member Text */
.member-text {
  color: white;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0.5px;
}

/* RESPONSIVE DESIGN */

/* For tablets (768px and below) */
@media (max-width: 768px) {
  .portfolio-title {
    font-size: 60px;
    line-height: 70px;
  }

  .member-text {
    font-size: 18px;
    line-height: 34px;
  }

  .video-frame {
    max-width: 300px;
  }

  .overlay-text {
    font-size: 10px;
    padding: 6px 10px;
  }
}

/* For mobile devices (480px and below) */
@media (max-width: 480px) {
  .portfolio-title {
    font-size: 40px;
    line-height: 50px;
  }

  .member-text {
    font-size: 16px;
    line-height: 30px;
  }

  .video-frame {
    max-width: 250px;
  }

  .overlay-text {
    font-size: 9px;
    padding: 5px 8px;
  }
}