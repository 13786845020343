.proof-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, rgba(10, 10, 10, 0), #0a0a0a, #181818, #0a0a0a, rgba(10, 10, 10, 0));
    color: white;
    padding: 140px 250px;
    border-radius: 10px;
    margin-bottom: 200px;
  }
  
  .content {
    display: flex;
    align-items: flex-start; /* Aligns text properly */
    gap: 250px; /* Balanced spacing */
  }
  
  .proof-text {
    max-width: 50%;
    text-align: left; /* Ensures "t" and "w" align properly */
  }
  
  .trusted {
    font-size: 14.5px;
    letter-spacing: 1px;
    text-transform: uppercase;
    opacity: 0.9;
  }
  
  .proof-heading {
    font-size: 84px; /* Even larger for impact */
    font-weight: 600;
    line-height: 0.9; /* Tighter spacing ensures alignment */
    white-space: nowrap; /* Prevents breaking weirdly */
  }
  
  .ratings {
    display: flex;
    gap: 100px;
  }
  
  .rating {
    text-align: center;
  }
  
  .stars {
    font-size: 42px;
  }
  
  .rating-value {
    font-size: 76px;
    font-weight: bold;
    margin: 5px 0;
  }
  
  .rating-value span {
    font-size: 38px;
    opacity: 0.7;
  }
  
  .platform {
    font-size: 28px;
    opacity: 0.7;
  }
  /* === Responsive Design === */
@media (max-width: 1200px) {
  .proof-heading {
      font-size: 5vw;
  }

  .ratings {
      gap: 3vw;
  }

  .stars {
      font-size: 2.5vw;
  }

  .rating-value {
      font-size: 4vw;
  }

  .rating-value span {
      font-size: 1.8vw;
  }

  .platform {
      font-size: 1.8vw;
  }
}

@media (max-width: 1024px) {
  .proof-section {
      padding: 8vw 5vw;
  }

  .content {
      flex-direction: column;
      text-align: center;
      align-items: center;
  }

  .proof-text {
      max-width: 100%;
      text-align: center;
  }

  .ratings {
      flex-direction: row;
      justify-content: center;
  }
}

@media (max-width: 768px) {
  .proof-heading {
      font-size: 7vw;
  }

  .ratings {
      flex-direction: column;
      gap: 2vw;
  }

  .stars {
      font-size: 4vw;
  }

  .rating-value {
      font-size: 5vw;
  }

  .rating-value span {
      font-size: 2.5vw;
  }

  .platform {
      font-size: 2.5vw;
  }
}

@media (max-width: 480px) {
  .proof-heading {
      font-size: 8vw;
      line-height: 1.1;
      margin-bottom: 0;  /* Reduced space */
  }

  .stars {
      font-size: 5vw;
      margin-top: 0;  /* No extra space */
  }

  .rating-value {
      font-size: 6vw;
  }

  .rating-value span {
      font-size: 3vw;
  }

  .platform {
      font-size: 3vw;
  }
}
