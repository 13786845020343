.benefit-container {
  background: url("/public/images/Background-light.jpg") no-repeat center center/cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10%;
}

.benefit-content {
  color: var(--cred-club-white, #FFF);
  max-width: 750px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 227px;
}

.benefit-title {
  font-family: var(--font-family-Font-1, Inter);
  font-size: 96.141px;
  font-style: normal;
  font-weight: var(--font-weight-700, 700);
  line-height: var(--line-height-108, 108px);
  letter-spacing: var(--letter-spacing--0_5, -0.5px);
}

.benefit-list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.benefit-list ul {
  list-style: none;
  padding: 0;
}

.benefit-list li {
  font-size: 22.807px;
  font-family: var(--font-family-Font-2, Inter);
  font-weight: var(--font-weight-400, 400);
  line-height: 30.409px;
  letter-spacing: 0.38px;
  color: var(--www-yieldstreet-com-white, #FFF);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.tick-icon {
  width: 35.477px;
  height: 35.477px;
  flex-shrink: 0;
  margin-right: 10px;
  opacity: var(--opacity-100, 1);
}


@media (max-width: 1200px) {
  .benefit-title {
    font-size: 6rem;
    line-height: 7rem;
  }

  .benefit-list {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 1024px) {
  .benefit-container {
    padding: 5%;
    justify-content: center;
    text-align: center;
  }

  .benefit-content {
    max-width: 90vw;
  }

  .benefit-title {
    font-size: 5rem;
    line-height: 6rem;
    white-space: normal;
    text-align: center;
  }

  .benefit-list {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .benefit-list li {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .benefit-title {
    font-size: 4rem;
    line-height: 5rem;
  }

  .benefit-list li {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .benefit-title {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .benefit-list li {
    font-size: 0.9rem;
  }

  .tick-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}