/* Base container */
.story-container {
    text-align: center;
    background-color: black;
    color: white;
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
  }
  
  /* Title */
  .story-title1 {
    color: var(--www-titan-com-alabaster, var(--color-grey-97, #F8F8F8));
text-align: center;

/* www.titan.com/Semantic/Heading 1 */
font-family: var(--font-family-Font-1, Inter);
font-size: 72.344px;
font-style: normal;
font-weight: var(--font-weight-500, 500);
line-height: var(--line-height-76, 76px); /* 105.054% */
letter-spacing: var(--letter-spacing--0_8, -0.8px);
  }
  
  /* Image */
  .story-image {
    width: 90%;
    max-width: 600px;
    height: 200px;
    background-image: url("/public/images/skyline-foreground-3.png (1).png"); /* Replace with actual image */
    background-size: cover;
    background-position: center;
    border-radius: 50px;
    margin: 0 auto 20px;
  }
  
  /* Subtitle */
  .story-subtext {
    font-size: 14px;
    color: gray;
    margin-bottom: 20px;
  }
  
  /* Gradient Text Effect */
  .gradient-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 60px;
    font-weight: 400;
    line-height: 82px; 
    letter-spacing: 0.69px;
    
    /* Radial Gradient */
    background: radial-gradient(100% 100% at 20% 20%, 
    rgba(255, 255, 255, 0.40) 30%, 
                #FFF 60%, 
                rgba(255, 255, 255, 0.447) 100%, 
                #000 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Additional Styling */
  .bold-text {
    font-weight: bold;
  }
  
  .mid-text {
    font-size: 16px;
  }
  
  .highlight {
    font-weight: bold;
    color: white;
  }
  
  /* Responsive Styles */
  
  /* Tablets (768px and above) */
  @media (min-width: 768px) {
    .story-title {
      font-size: 28px;
    }
  
    .story-image {
      height: 250px;
    }
  
    .gradient-text {
      font-size: 48px;
      line-height: 72px;
    }
  }
  
  /* Desktops (1024px and above) */
  @media (min-width: 1024px) {
    .story-container {
      padding: 60px 100px;
    }
  
    .story-title {
      font-size: 32px;
    }
  
    .story-image {
      max-width: 700px;
      height: 300px;
    }
  
    .gradient-text {
      font-size: 60px;
      line-height: 82px;
    }
  }
  
  /* Mobile screens (max-width: 480px) */
  @media (max-width: 480px) {
    .story-title {
      font-size: 22px;
    }
  
    .story-image {
      height: 180px;
    }
  
    .gradient-text {
      font-size: 32px;
      line-height: 48px;
    }
  }