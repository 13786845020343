/* Global Container */
.carousel-container {
    background: black;
    color: white;
    padding: 40px;
    text-align: center;
    max-width: 100%;
}

/* Heading */
.heading {
    color: var(--cred-club-white, var(--color-white-solid, #FFF));
    font-family: var(--font-family-Font-1, Inter);
    font-size: 79.102px;
    font-style: normal;
    font-weight: var(--font-weight-700, 700);
    line-height: var(--line-height-92, 92px);
}

/* Card Styling */
.card {
    background: black;
    border: 1px solid gold;
    height: 270px;
    padding: 20px;
    row-gap: 10px;
    border-radius: 10px;
    text-align: left;
    margin: 30px 15px; /* Adds gap between cards */
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: translateY(-5px);
}

.card-icon {
    font-size: 24px;
}

.card h3 {
    color: white;
    margin-top: 10px;
}

.card p {
    font-size: 14px;
    color: #bbb;
}

/* Risk and Return Sections */
.risk-section, .return-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
    margin-top: 10px;
}

/* Dots */
.dots {
    display: flex;
    gap: 5px;
}

.dot {
    width: 10px;
    height: 10px;
    background: #444;
    border-radius: 50%;
}

.filled {
    background: gold;
}

/* Progress Bar Styling */
.progress-slider {
    margin-top: 20px;
    position: relative;
    width: 100%;
}

.progress-track {
    background: #222;
    height: 5px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar {
    height: 5px;
    background: gold;
    width: 0%;
    transition: width 0.5s ease;
}

/* RESPONSIVE STYLES */

/* Tablet */
@media (max-width: 1024px) {
    .heading {
        font-size: 1.8rem;
    }
    .card {
        padding: 15px;
        margin: 20px 10px; /* Reduce gap slightly */
    }
}

/* Mobile */
@media (max-width: 768px) {
    .heading {
        font-size: 1.5rem;
        text-align: center;
    }
    .carousel-container {
        padding: 20px;
    }
    .card {
        padding: 10px;
        margin: 15px auto; /* Center and space single column */
    }
    .progress-bar {
        height: 4px;
    }
}