/* General container styles */
.invest-container {
    background-color: black;
    color: white;
    min-height: 100vh;
    padding: 40px 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* Title styling */
  .invest-title {
    margin: 100px;
    color: #FBC76C;
text-align: center;
font-family: var(--font-family-Font-1, Inter);
font-size: 64px;
font-style: normal;
font-weight: var(--font-weight-500, 500);
line-height: 70px; /* 109.375% */
letter-spacing: var(--letter-spacing--0_24, -0.24px);
  }
  
  /* Property grid - Responsive */
  .property-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Property card */
  .property-card {
    background-color: #1a1a1a;
    border-radius: 12px;
    padding: 15px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .property-card:hover {
    transform: scale(1.02);
  }
  
  /* Property image */
  .property-image {
    position: relative;
  }
  
  .property-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .featured-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: gold;
    color: black;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  /* Property details */
  .property-details {
    margin-top: 10px;
  }
  
  .property-meta {
    font-size: 12px;
    color: gray;
  }
  
  .property-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .property-price {
    color: gold;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .property-info {
    font-size: 14px;
    color: gray;
    margin-top: 5px;
  }
  
  /* Follow button */
  .follow-button {
    background-color: gold;
    color: black;
    border: none;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .follow-button:hover {
    background-color: #d4af37;
  }
  
  /* Responsive Design */
  
  /* Mobile screens */
  @media (max-width: 600px) {
    .invest-title {
      margin: 0px;
      font-size: 24px;
    }
  
    .property-grid {
      grid-template-columns: 1fr;
    }
  
    .property-card {
      width: 100%;
    }
  }
  /* Tablet screens */
  @media (min-width: 601px) and (max-width: 1024px) {
    .invest-title {
      font-size: 28px;
    }
    .property-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Large screens */
  @media (min-width: 1025px) {
    .property-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }