/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: "Inter", sans-serif;
    background-color: #000;
    color: #9F9E9E;
    text-align: center;
  }
  
  /* Background Video */
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    position: absolute;
    top: 0;
    width: 100%;
  }
  /* Logo styling */
.logo {
  height: 120px;/* Adjust the size of the logo */
  width: auto;
}
  .menu-btn {
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    margin-top: 150px;
    text-align: center;
    color: #9F9E9E;
    z-index: 1;
    height: 40vh;
    padding: 20px;
  }
  .hero-title {
    font-size: 97.969px;
    font-style: normal;
    font-weight: 700;
    line-height: 100px; /* 102.073% */
    letter-spacing: -0.4px;
  }
  
  .hero-title span {
    font-weight: bold;
    color: #fff;
  }
  .hero-subtitle {
    margin-top: 10px;
    font-size: 18px;
    opacity: 0.8;
  }
  
  /* Features Section */
  .features1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
    padding: 20px;
    z-index: 1;
  }
  .feature-card {
    color: #FFF;
    background-color: #00000084;
    padding: 30px;
    font-family: "Inter", sans-serif;
    font-size: 16.525px;
    font-style: normal;
    border-radius: 30px;
    font-weight: 400;
    border-left: 0.688px solid #FBC76C;
    line-height: 21.032px;
    display: inline-block;
    margin: 10px;
    text-align: center;
    width: 360px; /* Adjust width as needed */
  }
  .feature-icon {
    width: 40px; /* Same size as the icons */
    height: 40px;
    margin-bottom: 10px;
    background-color: #ffffff;
    padding: 8px;
    border-radius: 10px;
  }
  
  .feature-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .hero-title {
      font-size: 72px;
      line-height: 90px;
    }
  
    .hero-subtitle {
      font-size: 16px;
    }
  
    .feature-card {
      width: 220px;
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 56px;
      line-height: 70px;
    }
  
    .navbar {
      padding: 15px 20px;
    }
  
    .features {
      flex-direction: column;
      align-items: center;
    }
  
    .feature-card {
      width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .hero-title {
      font-size: 40px;
      line-height: 50px;
    }
  
    .hero-subtitle {
      font-size: 14px;
    }

    .navbar {
      padding: 10px 15px;
    }
  
    .menu-btn {
      font-size: 20px;
    }
  }