/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: "Inter", sans-serif;
    background-color: #000000;
    color: #B5B3B3;
    text-align: center;
  }
  
  /* Container */
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
  }
  
  /* Small Text */
  .small-text {
    color: var(--cred-club-white, var(--color-white-solid, #FFF));

    /* cred.club/Inter/Semi Bold */
    font-family: var(--font-family-Font-1, Inter);
    font-size: 24.891px;
    font-style: normal;
    font-weight: var(--font-weight-600, 600);
    line-height: var(--line-height-36, 36px); /* 144.633% */
    letter-spacing: var(--letter-spacing-6, 6px);
  }
  
  /* Content Box */
  .content-box {
    padding: 20px 30px;
    max-width: 900px;
    background: rgb(0, 0, 0);
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Text Inside Content Box */
  .content-box p {
    color: #B5B3B3;
    text-align: left;
    font-family: Inter;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-100, 100px); /* 166.667% */
    letter-spacing: var(--letter-spacing-1_26, 1.26px);
  }
  .bold {
    color: #FFF;
font-family: Inter;
font-size: 60px;
font-style: normal;
font-weight: 800;
line-height: var(--line-height-100, 100px); /* 166.667% */
letter-spacing: var(--letter-spacing-1_26, 1.26px);
  }
  
  /* Highlighted Text */
  .highlight1 {
    font-weight: bold;
    color: #f7b500;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .content-box {
      max-width: 500px;
      padding: 20px;
      text-align: left;
    }
    .bold {
      font-size: 15px;
      line-height: 1.4;
    letter-spacing: 1.2;
    }
    .content-box p {
      font-size: 30px;
      line-height: 1.4;
    letter-spacing: 1.2;
    text-align: left;
    }
  
    .small-text {
      font-size: 18px;
    }
  }
  
  @media (max-width: 768px) {
    .content-box {
      max-width: 90%;
      padding: 15px;
    }
  
    .content-box p {
      font-size: 26px;
    }
  
    .small-text {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      min-height: auto;
      padding: 40px 20px;
    }
  
    .content-box {
      width: 100%;
      padding: 15px;
    }
  
    .content-box p {
      font-size: 22px;
      text-align: center;
    }
  
    .small-text {
      font-size: 14px;
    }
  }