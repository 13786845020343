/* .comparison-container {
  background: linear-gradient(to bottom, #2a2a2a, #111);
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  max-width: 1300px;
  margin: auto;
  font-family: "Inter", sans-serif;
}

.compare-text {
  padding-top: 60px;
  color: orange;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 100;
}

.title {
  font-size: 42px;
  font-weight: 300;
  margin: 10px 0;
  margin-bottom: 50px;
}
.lgg{
  font-size: 25px;
}
.brand {
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 15px 10px;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: 60px;
  margin-right: 60px;
}

.table-header span {
  text-align: center;
}

.yellow-text {
  color: orange;
  font-size: 16px; 
}

.table-header span:not(.lgg) {
  font-size: 10px;
  color: #bbb;
}

.table-section {
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  font-family: "Inter", sans-serif;
}

.table-section h4 {
  text-align: left;
  margin: 15px 0 10px;
  font-size: 16px;
  color: gray;
  margin-left: 10px;
  font-family: "Inter", sans-serif;
}

.table-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 15px 10px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.left-column {
  text-align: left;
}

.row-title {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.row-description {
  font-size: 12px;
  color: #aaa;
  display: block;
  margin-top: 4px;
}

.disclaimer {
  font-size: 12px;
  color: gray;
  margin-top: 20px;
} */












.comparison-container {
  background: linear-gradient(to bottom, #2a2a2a, #111);
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  max-width: 1300px;
  margin: auto;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.compare-text {
  padding-top: 60px;
  color: orange;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 100;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.title {
  font-size: 42px;
  font-weight: 300;
  margin: 10px 0 50px;
  line-height: 1.2;
}

.lgg {
  font-size: 25px;
  font-weight: 600;
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 15px 10px;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0 60px;
}

.table-header span {
  text-align: center;
}

.yellow-text {
  color: orange;
  font-size: 16px;
}

.table-header span:not(.lgg) {
  font-size: 14px;
  color: #bbb;
  font-weight: 400;
}

.table-section {
  margin-top: 20px;
  padding: 0 50px;
}

.table-section h4 {
  text-align: left;
  margin: 15px 0 10px 10px;
  font-size: 16px;
  color: #aaa;
  font-weight: 500;
  text-transform: uppercase;
}

.table-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 15px 10px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.left-column {
  text-align: left;
  padding-right: 20px;
}

.row-title {
  font-size: 16px;
  font-weight: 500;
  display: block;
  letter-spacing: normal;
  margin-bottom: 5px;
  line-height: 1.4;
}

.row-description {
  font-size: 14px;
  color: #9C9C9C;
  display: block;
  letter-spacing: 0 !important; 
  font-feature-settings: 'kern' 1;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
  font-kerning: normal;
  word-spacing: normal;
}
.table-row > span {
  text-align: center;
  font-size: 18px;
}

.disclaimer {
  font-size: 12px;
  color: #666;
  margin-top: 40px;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* @media (max-width: 768px) {
  .comparison-container {
    padding: 30px 20px;
  }
  
  .title {
    font-size: 28px;
    margin-bottom: 30px;
  }
  
  .table-header,
  .table-section {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
  }
  
  .table-header span:not(.lgg) {
    font-size: 10px;
  }
  
  .row-title {
    font-size: 14px;
  }
  
  .row-description {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .table-section h4 {
    font-size: 12px;
    margin: 10px 0 6px 6px;
  }
  
  .table-header,
  .table-section {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media (max-width: 480px) {
  .table-header,
  .table-section {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .table-section h4{
    font-size: 10px;
  }

  .table-row {
    grid-template-columns: 1.5fr 0.7fr 0.7fr 0.7fr;
  }

  .row-description {
    display: none; 
  }
}
@media (max-width: 400px) {
  .table-section h4 {
    font-size: 11px;
    letter-spacing: 0.2px;
  }
  
  .row-description {
    display: none; 
  }
} */


/* Large Tablets (768px - 1024px) */
@media (max-width: 1024px) {
  .comparison-container {
    padding: 35px;
  }
  .title {
    font-size: 36px;
  }
  .table-header,
  .table-section {
    margin: 0 40px;
    padding: 0 30px;
  }
}

/* Standard Tablets (600px - 768px) */
@media (max-width: 768px) {
  .comparison-container {
    padding: 30px 20px;
  }
  .title {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .lgg {
    font-size: 22px;
  }
  .table-header,
  .table-section {
    margin: 0 20px;
    padding: 0 15px;
  }
  .table-section h4 {
    font-size: 14px;
  }
  .row-title {
    font-size: 15px;
  }
  .row-description {
    font-size: 13px;
  }
}

/* Large Phones (480px - 600px) */
@media (max-width: 600px) {
  .title {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .compare-text {
    padding-top: 40px;
  }
  .table-header {
    grid-template-columns: 1.8fr 1fr 1fr 1fr;
    padding: 12px 5px;
  }
  .table-header span:not(.lgg) {
    font-size: 11px;
  }
  .table-section h4 {
    font-size: 13px;
    margin: 12px 0 8px 8px;
  }
  .row-title {
    font-size: 14px;
  }
  .table-row > span {
    font-size: 16px;
  }
}

/* Medium Phones (400px - 480px) */
/* Mobile-First Responsive Adjustments */

/* Small Phones (up to 480px) */
@media (max-width: 480px) {
  .comparison-container {
    padding: 20px 12px; /* Tighter side padding */
  }

  .title {
    font-size: 22px;
    margin-bottom: 30px;
    padding: 0 5px; /* Prevent text touching edges */
  }

  /* Table Structure Adjustments */
  .table-header,
  .table-section {
    margin: 0 2px; /* Minimal side margins */
    padding: 0 5px; /* Tight inner padding */
  }

  /* Fix h4 sizing and alignment */
  .table-section h4 {
    font-size: 11px !important; /* Force smaller size */
    margin: 8px 0 6px 5px; /* Tighter spacing */
    letter-spacing: 0.2px;
    white-space: normal; /* Allow wrapping */
    line-height: 1.3;
    padding-right: 5px; /* Prevent overflow */
  }

  /* Table Grid Adjustments */
  .table-row {
    grid-template-columns: 1.8fr 0.8fr 0.8fr 0.8fr; /* Better column ratios */
    padding: 10px 2px; /* Tighter row padding */
    gap: 4px; /* Reduced gap between columns */
  }

  /* Text Improvements */
  .row-title {
    font-size: 13px;
    margin-bottom: 2px;
    line-height: 1.3;
  }

  .row-description {
    font-size: 11px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines max */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  /* Header Text Sizing */
  .table-header span:not(.lgg) {
    font-size: 9px;
  }

  .lgg {
    font-size: 16px;
  }
}

/* Extra Small Phones (up to 360px) */
@media (max-width: 360px) {
  .table-section h4 {
    font-size: 10px !important;
    margin-left: 3px;
  }
  
  .row-title {
    font-size: 12px;
  }
  
  .table-row {
    grid-template-columns: 1.7fr 0.7fr 0.7fr 0.7fr; /* Even tighter columns */
  }
}

/* Fix for h4 text alignment */
.table-section h4 {
  text-align: left;
  width: calc(100% - 10px); /* Prevent overflow */
}