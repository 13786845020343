.contact-container {
  height: auto; /* Adaptable height */
  background: rgba(17, 17, 17, 1); /* Dark background */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px;
  font-family: "Inter", sans-serif; /* Corrected font-family */
  flex-wrap: wrap;
}

.content-wrapper {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.contact-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.indicator {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
}

.contact-text {
  font-size: 14px;
  letter-spacing: 1px;
  opacity: 0.8;
}

.contact-title {
  font-size: 57.5px;
  margin: 10px 0;
  font-weight: lighter;
}

/* Button Styling */
.contact-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  color: #000;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: fit-content;
  min-width: 150px;
}

.contact-btn:hover {
  background: #ccc;
}
.icon {
  transition: 0.3s;
}
.contact-btn:hover .icon {
  transform: translateX(5px);
}
/* 🔹 Responsive Adjustments */
@media (max-width: 1024px) {
  .contact-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 30px;
  }
  .contact-title {
      font-size: 45px; /* Adjusted for tablets */
  }
}
@media (max-width: 768px) {
  .contact-container {
      padding: 20px;
  }
  .contact-title {
      font-size: 38px; /* Adjusted for mobile */
  }
  .contact-text {
      font-size: 12px;
  }
  .contact-btn {
      font-size: 14px;
      padding: 10px 20px;
  }
}
@media (max-width: 480px) {
  .contact-container {
      padding: 15px;
      justify-content: center;
      text-align: center;
  }
  .contact-title {
      font-size: 32px; /* Smaller for small screens */
  }

  .contact-btn {
      width: 100%;
      text-align: center;
      justify-content: center;
  }
}