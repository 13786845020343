/* Hero section container */
.hero-container {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  min-height: 60vh;
  font-family: Arial, sans-serif;
  width: 100%;
}
/* Left text content */
.hero-content {
  width: 100%; /* Ensures text takes up full width */
  text-align: left;/* Aligns text centrally */
  padding-right: 20px; /* Adds space on the right */
}
/* Hero title style */
.hero-title1 {
  color: var(--cred-club-white, var(--color-white-solid, #FFF));
  font-family: var(--font-family-Font-1, Inter);
  font-size: 64px;
  font-style: normal;
  font-weight: var(--font-weight-700, 700);
  line-height: var(--line-height-92, 92px); /* 143.75% */
}

/* Hero subtitle style */
.hero-subtitle {
  color: var(--cred-club-white, var(--color-white-solid, #FFF));

/* cred.club/Inter/Medium */
font-family: var(--font-family-Font-1, Inter);
font-size: 21.141px;
font-style: normal;
font-weight: var(--font-weight-500, 500);
line-height: var(--line-height-39, 39px); /* 184.479% */
letter-spacing: var(--letter-spacing-0_5, 0.5px);
}
/* Highlighted text */
.hero-highlight {
  color: gold;
  font-weight: bold;
}

/* Right-side animated graphic (if needed) */
.hero-graphic {
  width:100%;
  height: 300px;
  background-image: url("/public/images/ccbp-fold-poster.jpg (1).png"); /* Replace with actual graphic */
  background-size: cover;
  background-position: center;
  border-radius: 10px; /* Optional: Add some rounding for a sleek effect */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
  }

  .hero-content {
    max-width: 90%;
    width: 100%;
    padding-right: 0; /* Remove right padding for smaller screens */
  }

  .hero-graphic {
    width: 100%; /* Make graphic take full width */
    height: 250px; /* Reduce height for smaller screens */
    margin-top: 20px; /* Add margin between text and image */
  }
}