/* Full-screen section */
.wealth-section {
    background-color: black;
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 10px;
    width: 100%;
  }
  /* Title and heading styles */
  .section-title {
    color: #fcb900;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 100;
  }
  .main-heading {
    color: var(--www-titan-com-alabaster, var(--color-grey-97, #F8F8F8));
text-align: center;
font-family: var(--font-family-Font-1, Inter);
font-size: 97px;
font-style: normal;
font-weight: var(--font-weight-500, 500);
line-height: 100px; /* 103.093% */
letter-spacing: var(--letter-spacing--0_24, -0.24px);
  }
  
  .subtext {
    color: #aaa;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }
  
  /* Feature layout - each row has a title on left and text on right */
  .features {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1000px;
    margin-top: 40px;
    width: 100%;
  }
  
  /* Feature row: title on the left, text on the right */
  .feature {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 40px;
    padding: 15px 0;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
  }
  
  .feature-title {
    font-size: 21px;
    font-weight: 100;
    text-align: left;
  } 
  
  .feature-text {
    color: #aaa;
    font-size: 14px;
    text-align: left;
  }
  
  /* === Responsive Design === */
@media (max-width: 1200px) {
  .main-heading {
      font-size: 5vw;
  }

  .feature {
      gap: 30px;
      padding: 15px 8%;
  }

  .feature-title {
      font-size: 1.8vw;
  }

  .feature-text {
      font-size: 1.2vw;
  }
}

@media (max-width: 1024px) {
  .main-heading {
      font-size: 4.5vw;
  }

  .feature {
      grid-template-columns: 1fr;
      text-align: center;
  }

  .feature-title {
      text-align: center;
      font-size: 2.2vw;
  }

  .feature-text {
      text-align: center;
      font-size: 1.5vw;
  }
}

@media (max-width: 768px) {
  .main-heading {
      font-size: 6vw;
  }

  .feature-title {
      font-size: 4vw;
  }

  .feature-text {
      font-size: 2.5vw;
  }

  .feature {
      gap: 20px;
  }
}

@media (max-width: 480px) {
  .main-heading {
      font-size: 8vw;
      line-height: 1.2;
  }

  .subtext {
      font-size: 1rem;
  }

  .feature-title {
      font-size: 5vw;
  }

  .feature-text {
      font-size: 3vw;
  }

  .feature {
      gap: 15px;
      padding: 15px 5%;
  }
}