.rewards-container {
    text-align: center;
    color: white;
    padding: 40px 20px;
    position: relative;
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  
  .video-section {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .rewards-content {
    position: relative;
    text-align: center;
    max-width: 80%;
    z-index: 1;
  }
  
  .rewards-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .rewards-text {
    font-size: 18px;
    line-height: 1.6;
  }