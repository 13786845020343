@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Domine:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  color: #1e1e1e; /* Darker text for contrast */
  background-color: #000000; /* Light, professional background */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Code styles */
code {
  font-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #000000; /* Sets code text color to black */
}

body, html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}