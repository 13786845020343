/* General FAQ Container */
.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background-color: #121212;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.1);
  margin-top: 100px;
}

/* FAQ Title */
.faq-title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
  color: #ffffff;
}

/* FAQ List */
.faq-list {
  display: flex;
  flex-direction: column;
}

/* Each FAQ Item */
.faq-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px 0;
  transition: all 0.3s ease-in-out;
}

/* FAQ Question Styling */
.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.3s ease;
  color: #d3d3d3;
  padding: 15px;
  border-radius: 5px;
}

.faq-question:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

/* Expand/Collapse Icon */
.faq-icon {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.faq-item.open .faq-icon {
  transform: rotate(180deg);
}

/* FAQ Answer - Hidden by default */
.faq-answer {
  margin-top: 10px;
  font-size: 16px;
  color: #bbb;
  line-height: 1.6;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.faq-item.open .faq-answer {
  max-height: 500px; /* Adjust this value as needed */
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-container {
    padding: 20px;
    max-width: 90%;
  }

  .faq-title {
    font-size: 28px;
  }

  .faq-question {
    font-size: 16px;
    padding: 12px;
  }

  .faq-answer {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .faq-container {
    padding: 15px;
    max-width: 95%;
  }

  .faq-title {
    font-size: 24px;
  }

  .faq-question {
    font-size: 15px;
    padding: 10px;
  }

  .faq-answer {
    font-size: 13px;
  }
}