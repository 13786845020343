.footer {
    background-color: #000;
    color: white;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    position: relative;
  }
  
  .footer-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .footer-section h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #b3b3b3;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section li {
    font-size: 14px;
    color: #d3d3d3;
    margin-bottom: 8px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .footer-section li:hover {
    color: #fff;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    font-size: 12px;
    color: #aaa;
  }
  
  .footer-links span {
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .footer-links span:hover {
    color: #fff;
  }
  
  .footer-disclosure {
    font-size: 12px;
    color: #777;
    text-align: center;
    max-width: 800px;
    margin: 20px auto;
    line-height: 1.6;
  }
  
  .contact-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f2a900;
    color: black;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  .contact-button:hover {
    background-color: #c98b00;
  }